.MuiPickersBasePicker-pickerViewLandscape {
  margin: 0 auto;
}

.MuiPickersDay-daySelected {
  color: #fff !important;
}

.dashboard-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.first-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.second-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.tiles-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.calendar {
  width: 317.5px;
}

.monthly-chart {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.channel-chart {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 750px;
}

@media screen and (max-width: 1430px) {
  .customers {
    width: 50%;
  }

  .dashboard-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .second-column {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media screen and (max-width: 797px) {
  .dashboard-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .second-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tiles-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .monthly-chart {
    margin: 0 auto;
  }
}
