.bg-active {
  background-color: #e8f5e1 !important;
}

.aside-menu-item {
  color: #6bc200 !important;
}

.aside-menu-item:hover {
  background-color: rgba(107, 194, 0, 0.3) !important;
  border-radius: 4px;
}

.text-active {
  color: rgba(107, 194, 0, 0.3) !important;
}

a:hover .menu-text {
  color: #6bc200 !important;
}

a:hover .menu-svg {
  fill: #6bc200;
  fill-opacity: 1;
}

.menu-item.menu-item-open > .menu-link {
  background-color: rgba(107, 194, 0, 0.3) !important;
  border-radius: 4px;
}

.menu-item.menu-item-open > .menu-link .menu-text {
  color: #6bc200 !important;
}

.menu-item.menu-item-open > .menu-link .menu-svg {
  fill: #6bc200;
}

.menu-icon svg {
  margin-left: -2px;
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.menu-arrow {
  color: #6bc200;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow {
  color: #6bc200 !important;
}
