.body-pricing-override {
  display: flex !important;
  flex-direction: column !important;
  @media (min-width: 992px) and (max-width: 1470px) {
    display: inline-block !important;
  }
}

.swipeable-list {
  display: none;
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
}

.user-page-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.login-background {
  background-color: 407a28;
  background-size: cover;
  background-position-y: 50%;
}

.price-request-error {
  font-family: Montserrat-Regular;
  font-weight: 700;
  color: #929292;
  font-size: 1.4rem;
}

.select-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.input-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.submission-header-box {
  background-color: #565252;
  height: 56px;

  .submission-header-title {
    color: white;
    font-size: 1.6rem;
    font-family: Montserrat-Regular;
  }
}

.desktop-header-wrapper {
  background-color: #565252;
  height: 56px;
  color: white;
  font-size: 1.6rem;
  font-family: Montserrat-Regular;
}

.mobile-crop-card {
  border: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  cursor: pointer;
}

.crop-card-wrapper {
  border-top: 1px solid #ebebec;
  border-bottom: 1px solid #ebebec;
  font-family: Montserrat-Regular;
}

.disabled-card {
  background-color: #ececed !important;
}

.disabled-item {
  background-color: #6bc200 !important;
  opacity: 0.8;
}

.crop-card-image {
  min-width: 84.656084656085px;
  max-width: 100px;
  height: 125px;
  background-size: contain;
  background-position: 50% 50%;
}

.crop-card-main-title {
  font-size: 1.1em;
  color: #252525;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.8px;
  line-height: 22px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.crop-card-secondary-title {
  font-size: 1.1rem;
  color: #929292;
  letter-spacing: -0.8px;
  line-height: 22px;
  font-weight: 700;
  text-align: left;
  padding: 0;
  margin: 0;
}

.crop-card-low-title {
  color: #929292;
  letter-spacing: -0.8px;
  line-height: 22px;
  font-weight: 300;
  text-align: left;
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
}
.crop-card-pricing {
  color: #6bc200;
  font-size: 1.3rem;
  font-weight: 300;
}

.crop-card-pricing-text {
  font-size: 1.1rem;
  color: #929292;
  font-weight: 300;
}
.crop-card-pricing-title {
  font-size: 1.1rem;
  color: #929292;
  font-weight: 700;
}
.crop-card-best {
  font-family: Poppins-Regular;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 3px 5px;
  border-radius: 8px;
  background-color: #6bc200;
  color: #fff;
  height: 18px;
}

.crop-edit-wrapper {
  font-family: Montserrat-Regular;
  .crop-edit-main-title {
    font-size: 1.4em;
    color: #252525;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.8px;
    line-height: 22px;
    text-align: left;
    overflow: hidden;
  }
  .crop-edit-secondary-title {
    font-size: 1.3rem;
    color: #929292;
    letter-spacing: -0.8px;
    line-height: 22px;
    font-weight: 700;
    text-align: left;
  }
  .crop-edit-low-title {
    font-size: 1.1rem;
    color: #929292;
    letter-spacing: -0.8px;
    line-height: 22px;
    font-weight: 300;
    text-align: left;
  }
  .crop-edit-price-input {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    font-size: 4rem;
  }
  .crop-edit-price-title {
    font-size: 1.2rem;
    color: #929292;
    font-weight: 700;
    letter-spacing: -0.9px;
    line-height: 22px;
    margin-top: 10;
  }
  .crop-edit-selector {
    border: 1px solid rgba(100, 167, 11, 0.5);
    color: #929292;
    width: 140px;
    height: 38px;
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 1.1rem;
    background-color: transparent !important;
  }
}

.crop-edit-selector-text {
  font-size: 1rem;
  color: #929292;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 22px;
}

.crop-edit-button {
  font-family: Poppins-Regular;
  font-size: 1.3rem !important;
}

.crop-na-btn {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  max-width: 70px;
  min-width: 70px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
}

.crop-na-remove {
  box-shadow: 0px 0px 2px rgba(255, 0, 0, 0.12),
    0px 2px 2px rgba(212, 12, 12, 0.24);
  color: #c20000;
  background-color: #ff000012;
}

.crop-duplicate-btn {
  box-shadow: 0px 0px 2px rgba(23, 208, 51, 0.12),
    0px 2px 2px rgba(12, 212, 59, 0.24);
  color: #fff;
  background-color: #6bc200;
}

.crop-na-edit-btn {
  font-family: Poppins-Regular;
  font-size: 1.3rem !important;
  padding: 12px 12px !important;
}

.crop-edit-region-selector {
  border: 1px solid rgba(100, 167, 11, 0.5);
  color: #929292;
  max-width: 250px;
  width: 100%;
  height: 38px;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: transparent;
}

.is-selected {
  background-color: #6bc200 !important;
  color: #fff !important;
  border: none !important;
}

.btn.btn-primary {
  background-color: #6bc200 !important;
  color: #fff !important;
  border: none;
}

.crop-desktop-price-input {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: #6bc200;
  font-size: 2.1rem;
  min-height: 100px;
}

.crop-desktop-best {
  font-family: Poppins-Regular;
  font-size: 10px !important;
  font-weight: 400;
  width: 100px !important;
  height: 30px !important;
  padding: 3px 5px;
  background-color: #6bc200;
  border: none;
  color: #fff;
  text-align: center;
}

.crop-desktop-box {
  font-family: Poppins-Regular;
  font-weight: 400;
  font-size: 12px;
  width: 100px !important;
  height: 30px !important;
  border: 1px solid rgba(100, 167, 11, 0.5);
  color: #929292;
  background-color: transparent !important;
}

.crop-edit-best-input {
  border: 1px solid rgba(100, 167, 11, 0.5);
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
  width: 140px !important;
  height: 38px !important;
}

.crop-desktop-best-input {
  background-color: #6bc200;
  border: none;
  color: #fff !important;
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
  width: 100px !important;
  height: 30px !important;
}
.crop-desktop-best-input::placeholder {
  color: #fff !important;
  opacity: 1 !important;
}

.crop-region-country {
  border: 1px solid rgba(100, 167, 11, 0.5);
  color: #929292;
  max-width: 230px;
  width: 100%;
  height: 40px;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: transparent;
}

.state-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: 50px;
  grid-gap: 1em;
  max-width: 400px;
}

.region-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: 50px;
  grid-gap: 1em;
  max-width: 400px;
}

.span-text {
  font-size: 18px !important;
  font-weight: 400 !important;
  margin: 30px 0 !important;
}

.message-box {
  border: 1px solid #6bc200;
  border-radius: 5px;
  font-family: Poppins-Regular;
  padding: 5px 10px;
  font-size: 1.2rem;
  font-weight: 400;
}

.message-box-wrapper {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.selector-item {
  border: 1px solid rgba(100, 167, 11, 0.5);
  color: #929292;
  max-width: 100px !important;
  width: 100% !important;
  height: 40px !important;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: transparent;
}

.selector-item-edit {
  border: 1px solid rgba(100, 167, 11, 0.5);
  color: #929292;
  width: 100% !important;
  height: 40px !important;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: transparent;
}

.reports-table {
  font-family: Poppins-Regular;
  .reports-table-header-item {
    background-color: #6bc200;
    color: #fff;
  }
  .reports-table-line-header {
    background-color: #6bc200;
    color: #fff;
  }
}

.MuiCheckbox-colorPrimary {
  color: #6bc200;
}

.stepper-wrapper {
  background-color: #565252;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .stepper-main {
    background-color: #565252;
  }
  .stepper-card {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-radius: 15px;
  }
  .stepper-title {
    font-weight: 800;
    font-size: 2rem;
  }
  .stepper-description {
    font-weight: 300;
    font-size: 1.3rem;
  }
  .css-v2kfl7 {
    border-radius: 10px !important;
    background-color: #fbfcfd !important;
  }
  .css-1vauidd {
    border-radius: 10px !important;
    background-color: #fbfcfd !important;
    border-bottom: 0;
  }
  .css-tuv6q {
    border-radius: 10px !important;
    background-color: #fbfcfd !important;
    border-bottom: 0;
  }
  .stepper-summary-title {
    font-size: 2rem;
    font-weight: 600;
    text-decoration: underline;
  }
  span {
    color: #eb5e55 !important;
  }
  .MuiIconButton-label {
    color: #6bc200 !important;
  }
  table {
    color: #fbfcfd;
  }
  td {
    background-color: #fbfcfd;
  }
  .stepper-table-header {
    background-color: #6bc200;
    width: 20%;
  }
  .stepper-table-row-header {
    background-color: #6bc200;
  }
}

.subscriber-settings {
  table {
    color: #fbfcfd;
  }
  .stepper-table-header {
    background-color: #6bc200;
    width: 20%;
  }
  .stepper-table-row-header {
    background-color: #6bc200;
  }
}

.swipe-item {
  width: 100%;
  height: 100%;
  color: #fbfcfd;
  font-size: 1.6rem;
  text-align: center;
}

.swipe-duplicate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #6bc200;
}

.swipe-delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: #c20000;
}

.mobile-stepper-header {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 15px;
}

.payment-modal {
  border-radius: 14px;
  .payment-modal-button {
    font-size: 1.4rem;
    background-color: #6bc200;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 10px;
  }
  .payment-modal-title {
    background-color: #6bc200;
    color: #fff;
  }
  .MuiTypography-root.MuiTypography-h6 {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .payment-modal-card-icon {
    font-size: 2.5rem;
  }
}

.discount-selector-item {
  border: 1px solid rgba(100, 167, 11, 0.5);
  color: #929292;
  height: 40px !important;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 1.1rem;
  background-color: transparent;
  overflow-wrap: break-word;
}

.welcome-overlay {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  min-height: 40vh;
  .welcome-overlay-description {
    font-size: 1.3rem;
  }
}

.crop-upload-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 10px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  .crop-upload-cropper-wrapper {
    border-radius: 2px;
    margin-bottom: 8px;
    width: 100%;
    max-height: 330px;
    padding: 4px;
    box-sizing: border-box;
  }
  .crop-upload-cropper {
    position: relative;
    width: 100%;
    height: 200px;
    background: #333;
  }
}

.crop-upload-icon {
  margin: -13px 0 0 auto;
  color: #f64e60;
}
